@import '../../../../shared/styles/functions.scss';
@import '../../../../shared/styles/mixins.scss';
@import '../../../../shared/styles/variables.scss';

@mixin style($theme) {
  .actions {
    display: none;
    align-items: center;
    gap: 30px;

    @media screen and (min-width: 750px) {
      display: flex;
    }
  }

  .actionsList {
    display: flex;
    align-items: center;
    gap: 30px;
    list-style: none;
  }

  // .actionItem {
  // }

  .toggle-mobile-nav-btn {
    display: flex;
    align-items: center;
    height: 24px;
    width: 24px;
    display: none;

    svg {
      height: 24px;
      width: 24px;

      rect {
        fill: dark-light($neon, $blue, $theme);
      }
    }

    @media screen and (max-width: 749px) {
      display: block;
    }
  }

  .mobileNav {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    transform: translateX(-100%);
    visibility: hidden;
    transition: all 0.35s;
    width: 100vw;
    height: 100vh;
    background-color: dark-light($black-80, $white, $theme);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    z-index: 99;

    &.open {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);

      @media screen and (min-width: 750px) {
        //   display: none;
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  .mobileNavHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobileActionsList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 39px;
    list-style: none;
  }
}

:global(.light) {
  @include style('light');
}

:global(.dark) {
  @include style('dark');
}
