@import '../../../../shared/styles/functions.scss';
@import '../../../../shared/styles/mixins.scss';
@import '../../../../shared/styles/variables.scss';

@mixin style($theme) {
  .headerLink {
    font-size: pxToRem(12);
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: dark-light($black-25, $gray-04, $theme);
    text-decoration: none;
    position: relative;
    transition: all 0.2s;

    &.mobile {
      text-transform: uppercase;
    }

    &.active {
      color: dark-light($neon, $dark-blue, $theme);
      font-weight: 700;
    }

    &:hover,
    &:active,
    &:focus,
    &.active {
      color: dark-light($neon, $dark-blue, $theme);

      &::before {
        content: '';
        position: absolute;
        bottom: -8px;
        height: 2px;
        background-color: dark-light($neon, $dark-blue, $theme);
        width: 100%;
        left: 0;
      }
    }
  }
}

:global(.light) {
  @include style('light');
}

:global(.dark) {
  @include style('dark');
}
