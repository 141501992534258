@import '../../shared/styles/mixins.scss';
@import '../../shared/styles/variables.scss';

.container {
  margin-left:auto;
  margin-right:auto;
  @media (min-width:1800px) {
    width:1250px;
  }
  @media (max-width:1799px) {}
  @media (max-width:1799px) and (min-width:1200px) {
    width:1140px;
  }
  @media (min-width:1200px) {}
  @media (max-width:1199px) {}
  @media (max-width:1199px) and (min-width:900px) {
    width:840px;
  }
  @media (min-width:900px) {}
  @media (max-width:899px) {}
  @media (max-width:899px) and (min-width:600px) {
    width:570px;
  }
  @media (min-width:600px) {}
  @media (max-width:599px) {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.footer {
  background-color: var(--color-landingblack88);
  padding:80px 0;

  .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .logo {
      width: 120px;
      @media (max-width: 899px) {
        margin-bottom: 15px;
      }
    }

    // .media {
    //   display: flex;
    //   flex-direction: column;
    // }

    .mediaButtons {
      display: flex;
      margin-bottom: 30px;
    }

    .socialMediaLinks {
      background-color: var(--color-landingblack5);
      border-radius: 99999px;
      width: 30px;
      height: 30px;
      @include centerFlexContent;
      margin-right: 10px;
    }

    .links {
      display: flex;
      flex-direction: column;

      a {
        color: var(--color-landingblack50);
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.02em;

        &:hover {
          background-image: $gradient-primary;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
