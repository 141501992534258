@import '../../../../src/shared/styles/variables.scss';
@import '../../../shared/styles/mixins.scss';

.button {
  padding: 15px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.5s ease;
  color: var(--color-aux3);

  &.borderRadius {
    border-radius: 10px;
  }

  &.primary {
    background-color: #006dff;
    color: var(--color-aux3);

    &:hover {
      filter: brightness(110%);
    }
  }

  &.secondary {
    color: $color-black25;
  }

  &.tertiary {
    background-color: $color-primary;
    color: var(--color-aux3);

    &:hover {
      filter: brightness(110%);
    }
  }

  &.outlined {
    background-color: $color-black80;
    border: 1px solid $color-primary;
    color: $color-secondary;
    @include makeGradBorder($color-black80, 2px, 5px);
    &:hover {
      filter: brightness(110%);
    }
  }

  &.outlinedStatic {
    background-color: $color-black80;
    border: 1px solid $color-secondary;
    color: var(--color-aux70);
    &:hover {
      filter: brightness(110%);
    }
  }

  &.text {
    background-color: $color-black80;
    color: var(--color-aux21);
  }

  &.cancel {
    color: $color-black50;
    font-weight: 700;
    font-size: $font-size-m;
    margin-right: 2rem;
  }

  &.warning {
    background-color: $color-orange;
    &:hover {
      filter: brightness(90%);
    }
  }

  &.success {
    background-color: var(--color-green);
    filter: brightness(90%);
    &:hover {
      background-color: var(--color-green);
    }
  }

  &.danger {
    background-color: $color-red;
    &:hover {
      filter: brightness(90%);
    }
  }

  &.fullWidth {
    width: 100%;
    border-radius: 8px;
  }

  &.medium {
    width: 60%;
  }

  &.shorter {
    padding: 10px 30px;
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }

  &.tiny {
    padding: 10px 30px;
    max-width: 125px;
    height: 24px;
    padding: 5px 15px;
    font-size: 10px;
    font-weight: 700;
    line-height: 14.4px;
    letter-spacing: -2%;
  }

  &.shortcuts {
    padding: 3px 7px;
    max-width: 110px;
    width: 60%;
    font-size: $font-size-s;
  }

  &.small {
    color: var(--color-aux3);
    font-size: 13px;
    font-weight: 600;
    min-width: 100px;
    margin: 0.5rem;
    padding: 12px 30px !important;
    border-radius: pxToRem(5) !important;

    @include respond(tab-big) {
      border-radius: var(--border-radius3);
      padding: 15px 30px;
      color: var(--color-aux3);
      font-size: 13px;
      font-weight: 700;
    }
  }

  &.large {
    font-size: 14px;
    padding: 15px 30px;
  }

  &.muted {
    color: $color-black25;
  }

  &.disabled {
    cursor: not-allowed;
    color: $color-black50;
    // padding: 12px 30px;
    background: var(--color-aux37);
  }
}
