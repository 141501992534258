@import '../../../shared/styles/functions.scss';
@import '../../../shared/styles/mixins.scss';
@import '../../../shared/styles/variables.scss';

@mixin style($theme) {
  .header {
    display: flex;
    justify-content: center;
    height: 70px;
    background-color: dark-light($black-80, $white, $theme);
  }

  .container {
    background-color: dark-light($black-80, $white, $theme);
    display: grid;
    justify-items: center;
    z-index: 99;
    position: fixed;
    top: 0;
    height: 70px;
    width: 100%;
  }

  .content {
    width: 100%;
    max-width: 1440px;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 62em) {
      padding: 10px 20px;
    }
  }
}

:global(.light) {
  @include style('light');
}

:global(.dark) {
  @include style('dark');
}
