@import "../../../../src/shared/styles/variables.scss";

.backdrop {
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  transition: 0.2s;
  cursor: default;
  position: fixed;

  &.show {
    background-color: var(--color-backdrop);
    // background-color: rgba($color-black25, 0.02);
  }
}
