//Primary Palette
$color-primary: var(--color-primary);
$color-secondary: var(--color-secondary);
$color-grayish-navy-blue: var(--color-grayish-navy-blue);
$color-navy-blue: var(--color-navy-blue);

// White shouldn't be any other place than the DeWork logo
$color-white: var(--color-navy-blue);
//
$color-black5: var(--color-black5);
$color-black10: var(--color-black10);
$color-black25: var(--color-black25);
$color-black30: var(--color-black30);
$color-black50: var(--color-black50);
$color-black75: var(--color-black75);
$color-black80: var(--color-black80);
$color-black88: var(--color-black88);
$color-black100: var(--color-black100);

$color-green: #0eda95;
$color-red: #ff1616;
$color-yellow: #f2dd22;
$color-deso: #007bff;

$gradient-primary: linear-gradient(55.74deg, $color-primary, $color-secondary);
$gradient-primary-animated: linear-gradient(
  45deg,
  $color-primary,
  $color-secondary,
  $color-primary,
  $color-secondary
);
$gradient-secondary1: linear-gradient(
  to bottom left,
  $color-secondary,
  $color-primary
);
$gradient-secondary2: linear-gradient(to top, $color-secondary, $color-primary);
$gradient-roomcards: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0) 0%,
  rgba(0, 0, 0, 0.54) 100%
);
$gradient-primary2: linear-gradient(
  55.74deg,
  #2b71ff 31.75%,
  $color-secondary 78.57%
);

///// DO NOT USE COLORS BELOW

$background-grey1: $color-black5;
$background-grey2: $color-black75;

$color-orange1: #f5cbcb;
$color-orange: #f2994a;
$color-young-blue: #33ccff;

$box-shadow1: 0 0 50px rgb(#464646, 0.1);
$box-shadow2: 0 0 30px 2px rgba(0, 0, 0, 0.3);
$box-shadow3: 0 0 2px 0 rgba(0, 0, 0, 0.1);
$box-shadow4: -10px 20px 50px rgba(#464646, 0.4);
$box-shadow6: 0 20px 50px rgba(#464646, 0.4);
$box-shadow5: 0 20px 50px rgba($color-black75, 0.1);
$box-shadow7: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
$box-shadow8: -30px 30px 50px rgba(#464646, 0.2);
$box-shadow9: -10px 20px 50px rgba(#464646, 0.1);
$box-shadow10: 0 15px 30px rgba(51, 204, 255, 0.5);
$box-shadow11: 5px 0 15px rgba($color-primary, 0.15);
$box-shadow12: 0 0 0 2.60188px rgba(51, 204, 255, 0.2);
$box-shadow13: 0px 13px 26px rgba(0, 51, 153, 0.15);
$box-shadow14: 0px 13px 26px rgba(40, 249, 218, 0.15);

$border-radius1: 2px;
$border-radius2: 4px;
$border-radius3: 8px;
$border-radius4: 12px;
$border-radius5: 16px;
$border-radius6: 18px;
$border-radius7: 20px;
$border-radius8: 6px; //replace
$border-radius100: 9999px;

$font-size-xl: 1.5rem;
$font-size-x2: 26px;
$font-size-l: 1rem;
$font-size-m: 0.78rem;
$font-size-s: 0.7rem;
$font-size-xs: 0.6rem;
$font-family: 'Poppins', sans-serif;
$font-secondary: 'Inter', sans-serif;

$main-viewport-width: 1280px;
$header-height: calc(3rem + 15px);

$border-1: 1px solid $color-yellow;
$border-2: 1px solid transparent;
$border-3: 2.60188px solid var(--color-aux17);
$border-image-slice: 1;
$darkMode: true;

// Funcitons
@function pxToRem($pxVal) {
  @return ($pxVal/16) + 0rem;
}


/// NEW PALLETE: All color used in the application; comprises of both the light and dark colors.
$blue: #006dff;
$yellow: #f2dd22;
$green: #0eda95;
$red: #ff1616;
$white: #ffffff;

// Dark
$neon: #28f9da;
$ligth-blue: #2871ff;
$aqua: #28ffd8;
$navy: #101c3d;
$dark-navy: #141a2c;
$black-5: #f2f2f2;
$black-10: #e6e6e6;
$black-25: #c0c0c0;
$black-50: #808080;
$black-75: #404040;
$black-80: #181818;
$black-88: #101010;
$black-100: #000000;

// Light
$dark-blue: #003399;
$young-blue: #33ccff;
$black: #18243a;
$gray-01: $black-5;
$gray-02: #e1e1e1;
$gray-03: #b9b9b9;
$gray-04: #828282;
$gray-05: #4f4f4f;
$gray-06: #333333;
