@import "../../../../../src/shared/styles/variables.scss";
@import "../../../../../src/shared/styles/mixins.scss";

.AsideSection {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 45px 70px 90px 50px;
  height: 100%;
  background-color: var(--color-landingblack88);

  @media (max-width: 600px) {
    padding-bottom: 30px;
    padding-right : 42px;
    text-align: center;
  }
  img {
    max-width: 100%;
    object-fit: cover;
  }
}
.heading {
  display: inline-block;
  text-align: center;
  font-weight: 700;
  line-height: 1.4 !important;
  font-size: 30px !important;
  margin-top: 30px;
  margin-bottom: 2rem;
  letter-spacing: -0.03em;
  color: var(--color-aux3);

  > div {
    display: inline;
    white-space: nowrap;
  }
  .textGradient {
    background-image: $gradient-primary;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &_Typical {
    @media (min-width: 1163px) {
      margin-left: -145px;
      font-size: 48px;
      position: relative;
      top: -10px;
      right: -70px;
      text-align: center;
    }
  }
}
@media screen and (min-width: 600px) {
  .heading {
    font-size: 40px !important;
    text-align: center;
  }
}

.links {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  gap: 1.5rem;
  max-width: 100%;

  @media (max-width: 900px) {
    margin-top: 38px;
  }

  &__btn {
    max-height: 3rem;
    object-fit: cover;
  }
}
.HomeImage {
  text-align: center;
  img {
    max-height: 100%;

    @media (max-width: 600px) {
      height: 192px;
      width: 349px;
    }
  }

  
}

.logo {
  display: flex;
  > img {
    width: 120px; 
    @media (max-width: 600px) {
      margin-top: -20px;
      margin-left: -30px;
    }
  }
}
