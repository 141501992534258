@import "../../../../src/shared/styles/variables.scss";

.spinner {
  display: block;
  width: 30px;
  height: 30px;
  border: 5px solid var(--color-spinnerBorder);
  border-bottom-color: var(--color-spinner);
  border-radius: 99999px;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  &_big {
    @extend .spinner;
    border: 10px solid var(--color-spinnerBorder);
    border-bottom-color: var(--color-spinner);
    width: 80px;
    height: 80px;
  }
  &_medium {
    width: 45px;
    height: 45px;
  }
  &_small {
    width: 20px;
    height: 20px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
