@import "./../shared/styles/variables.scss";
@import "./../shared/styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

// MEDIA QUERIES LANDING JULIAN
.container {
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 1800px) {
    width: 1250px;
  }
  @media (max-width: 1799px) {
  }
  @media (max-width: 1799px) and (min-width: 1200px) {
    width: 1140px;
  }
  @media (min-width: 1200px) {
  }
  @media (max-width: 1199px) {
  }
  @media (max-width: 1199px) and (min-width: 900px) {
    width: 800px;
  }
  @media (min-width: 900px) {
  }
  @media (max-width: 899px) {
  }
  @media (max-width: 899px) and (min-width: 600px) {
    width: 570px;
  }
  @media (min-width: 600px) {
  }
  @media (max-width: 599px) {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.textGradient {
  @include gradientText;
}

#network {
  padding-top: 60px;
  padding-bottom: 60px;
}

.main {
  background-color: var(--color-landingblack80);
}

.sectionOne {
  background-color: var(--color-landingblack88);
  padding: 120px 0 120px 0;

  @media (max-width: 1200px) {
    padding: 60px 0 74px 0;
    background-color: var(--color-landingblack80);
  }

  h4 {
    font-size: 24px;
    color: var(--color-landingblack5);
  }
}

// .sectionTwo {
//   padding: 107px 11%;
//   text-align: left;
//   background-color: var(--color-landingblack80);

//   h2 {
//     color: var(--color-landingblack5);
//     font-size: 40px;

//     @media (max-width: 370px) {
//      font-size: 1.875rem;
//     }
//   }
// }

.containerWhy {
  text-align: left;
  background-color: var(--color-landingblack80);

  h2 {
    color: var(--color-landingblack5);
    font-size: 40px;
  }
  @media (max-width: 599px) {
    padding: 0 2rem;
  }
}

.arrowLink {
  display: flex;
  margin-top: 36px;

  p {
    margin-right: 15px;
    color: var(--color-landingblack5);
    align-self: center;
  }

  span {
    background-image: $gradient-primary;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.arrowWrap {
  @include centerFlexContent;
  width: 32px;
  height: 32px;
  border-radius: 99999px;
  background-color: var(--color-landingblack5);
}

.introContainer {
  h4 {
    font-size: 24px;
    color: var(--color-landingblack5);
  }
}

.introTitle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;

  h1 {
    font-family: $font-family;
    font-weight: 700;
    font-size: 45px;
    line-height: 130%;
    text-align: center;
    color: var(--color-landingblack5);
    letter-spacing: -0.02em;
    margin-bottom: 56px;
    @media (max-width: 600px) {
      font-size: 30px;
      flex-direction: row;
      margin-bottom: 45px;
    }
  }

  .description {
    font-size: 20px;
    color: var(--color-landingblack5);
    margin-bottom: 67px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.02em;
    text-align: center;

    @media (max-width: 900px) {
      font-size: 16px;
      margin-bottom: 54px;
      font-weight: 400;
      font-family: Helvetica Neue;
    }
  }
  h4 {
    text-align: center;
  }
}

.partners {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 42px;
  .image {
    height: auto;
  }
  @media (max-width: 1200px) {
    justify-content: space-around;
    flex-wrap: wrap;
    .image {
      width: 15%;
    }
  }
  @media (max-width: 599px) {
    .image {
      width: 35%;
    }
  }
}

.titleGradient {
  background-image: $gradient-primary;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 10px 0 10px;
   
}


.sectionThree {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-landingblack88);

  .title {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: var(--color-landingblack5);
    text-align: left;
    margin-bottom: 70px;
  }

  .textBlocks {
    display: flex;
    flex-wrap: wrap;
  }

  .featureItem {
    width: calc(33.333% - 50px);
    display: flex;
    margin: 0 25px 30px 25px;

    @media all and (max-width: 899px) {
      margin: 0;
      margin-bottom: 75px;
      width: 100%;
    }

    &:first-child {
      margin: 0 25px 70px 0;

      @media all and (max-width: 899px) {
        margin: 0;
        margin-bottom: 75px;
      }
    }

    &:nth-child(3n) {
      margin: 0 0 70px 25px;

      @media all and (max-width: 899px) {
        margin: 0;
        margin-bottom: 75px;
      }
    }

    &:nth-child(4) {
      margin: 0 25px 70px 0;

      @media all and (max-width: 899px) {
        margin: 0;
        margin-bottom: 75px;
      }
    }

    h1,
    p {
      color: var(--color-landingblack5);
      text-align: left;
    }

    h1 {
      font-size: 24px;
      letter-spacing: -0.02em;
      line-height: 28.8px;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      line-height: 19.6px;
    }
  }

  .featureText {
    margin-left: 15px;
    width: calc(100% - 70px);
  }

  .featureIcon {
    background: $gradient-primary;
    padding: 2px;
    width: 55px;
    height: 55px;
    border-radius: 99999px;
    position: relative;
  }

  .featureInnerIcon {
    background-color: var(--color-landingblack88);
    width: 100%;
    height: 100%;
    border-radius: 99999px;
    @include centerFlexContent;
    svg {
      width: 25px;
      path {
        fill: var(--color-landingblack5);
      }
    }
  }
}


.sectionFive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-landingblack80);
  padding: 70px 0 20px;

  .title {
    font-size: 36px;
    line-height: 43px;
    letter-spacing: -0.02em;
    color: var(--color-landingblack5);
    text-align: left;
    margin-bottom: 70px;

    @media(max-width: 899px) {
      font-size: 24px;
      line-height: 28.8px;
      margin-bottom: 50px;
    }
  }

  .textBlocks {
    display: flex;
    flex-wrap: wrap;
  }

  .featureItem {
    width: calc(33.333% - 50px);
    display: flex;
    margin: 0 25px 30px 25px;

    @media all and (max-width: 899px) {
      margin: 0;
      margin-bottom: 75px;
      width: 100%;
    }

    &:first-child {
      margin: 0 25px 70px 0;

      @media all and (max-width: 899px) {
        margin: 0;
        margin-bottom: 50px;
      }
    }

    &:nth-child(2) {
      margin: 0 70px 25px 0;

      @media all and (max-width: 899px) {
        margin: 0;
        margin-bottom: 50px;
      }
    }

    &:nth-child(3) {
      margin: 0 25px 70px 0;

      @media all and (max-width: 899px) {
        margin: 0;
        margin-bottom: 50px;
      }
    }

    &:nth-child(4) {
      margin: 0 25px 70px 0;

      @media all and (max-width: 899px) {
        margin: 0;
        margin-bottom: 50px;
      }
    }

    &:nth-child(5) {
      margin: 0 25px 70px 0;

      @media all and (max-width: 899px) {
        margin: 0;
        margin-bottom: 50px;
      }
    }

    &:nth-child(6) {
      margin: 0 25px 70px 0;

      @media all and (max-width: 899px) {
        margin: 0;
        margin-bottom: 50px;
      }
    }

    h1,
    p {
      color: var(--color-landingblack5);
      text-align: left;
    }

    h1 {
      font-size: 22px;
      letter-spacing: -0.02em;
      line-height: 28.8px;
      font-weight: 700;
      margin-bottom: 20px;
      @media(max-width: 899px) {
        font-size: 18px;
        line-height: 24.4px;
      }
    }

    p {
      font-size: 14px;
      line-height: 19.6px;
      font-weight: 600;

      @media(max-width: 899px) {
        font-size: 14px;
        line-height: 16.8px;
      }
    }
  }

  .featureText {
    margin-left: 15px;
    width: calc(100% - 70px);
  }

  .featureIcon {
    background: $gradient-primary;
    padding: 2px;
    width: 55px;
    height: 55px;
    border-radius: 99999px;
    position: relative;
  }

  .featureInnerIcon {
    background-color: var(--color-landingblack88);
    width: 100%;
    height: 100%;
    border-radius: 99999px;
    @include centerFlexContent;
    svg {
      width: 25px;
      path {
        fill: var(--color-landingblack5);
      }
    }
  }
}

.titles {
  text-align: center;
  font-weight: 700 !important;
  font-size: 50px !important;
  position: relative;
  padding-bottom: 3px;
}

.titles::after {
  content: "";
  position: absolute;
  width: 160px;
  height: 4px;
  background: $gradient-primary;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.sectionFour {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 160px;
  background-color: var(--color-landingblack80);
  @media (max-width: 600px) {
    max-width: 100%;
    padding: 0;
  }

  .testimonials {
    flex-wrap: wrap;
    @include centerFlexContent;

    .comments {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      letter-spacing: -0.01em;
      background-color: var(--color-landingblack88);
      color: var(--color-landingblack5);
      border-radius: 15px;
      width: 360px;
      font-size: 14px;
      padding: 50px 35px;
      @media (max-width: 850px) {
        width: 320px;
        font-size: 12px;
        padding: 20px 15px;
        margin-bottom: 20px;
        margin-top: 20px;
      }

      h3 {
        text-align: left;
        margin-bottom: 30px;
      }

      &:nth-child(2) {
        margin: 0 20px;
      }

      h4 {
        color: var(--color-landingsecondary);
      }
    }

    .testimonialsAvatar {
      width: 55px;
      height: 55px;
      position: relative;
      padding: 3px;
      background: $gradient-primary;
      border-radius: 99999px;
      margin-bottom: 25px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 99999px;
      }

      .testimonialsIcon {
        background-color: var(--color-landingblack5);
        border-radius: 99999px;
        width: 25px;
        height: 25px;
        position: absolute;
        @include centerFlexContent;
        right: 0;
        bottom: 0;
      }
    }
  }

  .testimonialsTittle {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    color: var(--color-landingblack5);
    font-size: 30px;
    letter-spacing: -0.02em;
    margin-bottom: 50px;
  }
}

section.gradFeature {
  padding: 80px 140px 80px 140px;
  background-color: var(--color-landingblack88);
  @media (max-width: 899px) {
    padding: 0px;
  }
  .gradDiv {
    border-radius: $border-radius6;
    background: $gradient-primary;
    padding: 50px;
    color: var(--color-landingblack5);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    letter-spacing: -0.02em;
    font-weight: 600;
    @media (max-width: 899px) {
      justify-content: space-around;
      padding: 30px;
    }
    .text {
      font-size: 36px;
      margin-bottom: 40px;
      font-weight: 700;
      @media (max-width: 899px) {
        margin-top: 0;
        font-size: 24px;
      }
    }
    .action {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 15px;
      font-size: 0.9em;
      .signin,
      .signup,
      // span {
      //   // white-space: nowrap;
      // }
      .signin,
      .signup {
        border-radius: $border-radius3;
        padding: 15px 25px;
        font-size: 16px;
        font-weight: 700;
        transition: 0.3s all;
        &:hover {
          background-color: $gradient-primary;
        }
      }
      .signin {
        border: 2px solid var(--color-landingsecondary);
        color: var(--color-landingblack5);
      }
      .signup {
        border: 2px solid var(--color-landingsecondary);
        color: var(--color-landingblack5);
      }
    }
  }
}

.wrapperLogin {
  margin: 50px 0 128px 0;
  @include centerFlexContent;

  .loginButton {
    line-height: 2;
    padding: 3px;
    font-weight: 600;
    border-radius: 7.5px;
    transition: all 0.3s ease;
    background: $gradient-primary;
    width: 155px;
    height: 47px;
    margin-right: 10px;
    font-size: 14px;
    color: var(--color-landingblack5);
    letter-spacing: -0.01em;

    .innerBtn {
      background-color: var(--color-landingblack88);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  h3 {
    color: var(--color-landingblack5);
    font-size: 14px;
    margin-right: 10px;
  }
}

.blockContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 0;
  }
}

.blockContainerRight {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  > div {
    &:last-of-type {
      display: flex;
      align-self: center;
    }
  }
  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    padding: 0;
    overflow: hidden;
  }
}

.blockContainerCenter {
  display: flex;
  flex-direction: column;
  color: var(--color-landingblack5);
  background-color: var(--color-landingblack88);
  align-items: center;
  justify-content: space-between;
  > p {
    font-size: 16px;
    font-weight: 900;
  }
  &_cardsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
    justify-items: center;
    > div {
      display: flex;
      width: 80%;
    }
  }
  &_banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 270px;
    width: 1120px;
    border-radius: 15px;
    margin-bottom: 50px;
    background: linear-gradient(36.42deg, #2871ff 8.42%, #28ffd8 90.15%);
    @media (max-width: 1200px) {
      width: 90%;
    }
    @media (max-width: 600px) {
      width: 90%;
    }
    > div {
      &:first-of-type {
        display: flex;
        align-self: center;
        font-size: 24px;
        font-weight: 700;
      }
      &:last-of-type {
        display: flex;
        font-size: 14px;
        font-weight: 900;
        align-self: center;
        > button {
          color: var(--color-landingblack5);
          &:last-of-type {
            border: 2px solid var(--color-landingblack5);
            padding: 10px;
            border-radius: 15px;
          }
        }
        > p {
          margin-left: 20px;
          margin-right: 20px;
          justify-self: center;
          align-self: center;
        }
      }
    }
  }
}

//Content with images at the left

.containerImageLeft {
  padding-left: 2rem;
  @media (max-width: 1200px) {
    width: 100%;
    padding-left: 0;
  }
  &_image {
    max-width: 28rem;
    max-height: 28rem;
    @media (max-width: 600px) {
      height: 17rem;
    }
  }
}

.containerImage {
  height: 600px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    max-width: 100%;
    margin-bottom: 20px;
  }
  @media (max-width: 1200px) {
    height: 450px;
    width: 450px;
    > img {
      height: auto;
      width: 100%;
      margin-bottom: 15px;
    }
  }
  @media (max-width: 800px) {
    height: 350px;
    width: auto;
    > img {
      height: 350px;
      width: auto;
      margin-bottom: 10px;
    }
  }
}

.containerContentLeft {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 6rem;
  text-align: left;
  width: 50%;
  //padding: 8rem 0 0 6rem;
  @media (max-width: 1200px) {
    width: 100%;
    margin-top: 50px;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin-top: 20px;
    padding: 1rem 0 0 2rem;
  }
  &_title {
    color: var(--color-landingblack5);
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    position: relative;
    display: inline-block;
    z-index: 2;
    @media (max-width: 600px) {
      font-size: 1.3rem;
      width: 100%;
    }
  }
  &_buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    > button {
      box-sizing: border-box;
      width: 80%;
      margin-top: 2rem;
      border-radius: 5px;
      height: 52px;
      border: 2px solid var(--color-landingblack75);
      color: var(--color-landingblack5);
      text-align: left;
      padding-left: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      font-size: 15px;
      a {
        &:last-of-type {
          margin-top: 5px;
        }
      }
      svg {
        margin-left: 10px;
        margin-right: 25px;
        width: 20px;
        height: 20px;
        padding: 4px;
        border-radius: 99999px;
        background-color: var(--color-landingblack5);
        path {
          fill: var(--color-landingblack10) 0;
        }
      }
    }
    @media (max-width: 600px) {
    }
  }
  &_button {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    color: var(--color-landingblack5);
    > span {
      margin-left: 5px;
      background: -webkit-linear-gradient(45deg, #2871ff, #28ffd8 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &_text {
    line-height: 140%;
    margin-top: 2.5rem;
    transition: 0.5s;
    width: 85%;
    color: var(--color-landingblack5);
    @media (max-width: 600px) {
      width: 100%;
      font-size: 0.9rem;
    }
  }
  &_vector {
    position: absolute;
    left: 5rem;
    top: 8.6rem;
    z-index: 1;
    //mobile and tablets
    @media (max-width: 414px) {
      left: 2rem;
      top: 5.8rem;
      width: 7.5rem;
    }
    @media (min-width: 415px) and (max-width: 899px) {
      display: none;
    }
    @media (min-width: 768px) and (max-width: 768px) {
      left: 2rem;
      top: 5.8rem;
      width: 9rem;
    }
    @media (min-width: 769px) and (max-width: 1023px) {
      display: none;
    }
    @media (min-width: 1024px) and (max-width: 1024px) {
      left: 2rem;
      top: 5.8rem;
      width: 9rem;
    }
  }
}

//Content with images at the Right

.containerImageRight {
  padding-right: 2rem;
  @media (max-width: 1200px) {
    width: 100%;
    padding-right: 0;
  }
  &_image {
    max-width: 28rem;
    max-height: 28rem;
    @media (max-width: 600px) {
      height: 17rem;
    }
  }
}

.containerContentRight {
  text-align: left;
  width: 50%;
  line-height : 46.8px;
  height: 585px;
  padding: 5rem 0 0 3rem;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 2.5rem 0 0 3rem;
    flex-direction: column-reverse;
    margin-bottom: 60px;
  }
  @media (max-width: 600px) {
    width: 100%;
    padding: 1rem 0 0 2rem;
    flex-direction: column-reverse;
    margin-bottom: 60px;
  }
  &_title {
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 46px;
    position: relative;
    display: inline-block;
    z-index: 2;
    color: var(--color-landingblack5);
    @media (max-width: 600px) {
      width: 100%;
      font-size: 1.5rem;
    }
  }
  &_text {
    color: var(--color-landingblack5);
    line-height: 2rem;
    margin-top: 2.5rem;
    transition: 0.5s;
    width: 85%;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  &_button {
    display: inline-block;
    margin-top: 2rem;
    @media (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-secondary); 
      font-weight: 700;
      font-size: 20px;
      padding: 15px 20px;
      @include makeGradBorderAnimated(var(--color-aux12), 3px, 10px);
      svg {
        margin-left: 17px;
        align-items: center;
        width: 20px;
        height: 20px;
        padding: 4px;
        border-radius: 99999px;
        background-color: var(--color-secondary);
        path {
          fill: #000000;;
        }
      }
    }
  }
  &_list {
    color: var(--color-landingblack5);
    margin-top: 1rem;
    padding-left: 1rem;
    width: 85%;
    @media (max-width: 600px) {
      width: 100%;
    }
    > li {
      line-height: 3rem;
      font-size: 18px;
      list-style: none;
      @media (max-width: 600px) {
        font-size: 14px;
      }
      > button {
        color: var(--color-landingblack5);
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .spanText{
          position: relative;
          bottom: 5px;
          right: 12px;
          margin-right: 10px;
          font-size: 12px;
        }
        svg {
          margin-right: 15px;
          width: 14.14px;
          height: 14.14px;
          path {
            fill: var(--color-landingblack5);
          }
        }
      }
      > p {
        line-height: initial;
        margin-bottom: 1rem;
        font-size: 14px;
      }
    }
    > div {
      height: 2px;
      margin-left: -15px;
      background-image: linear-gradient(311deg, #0e29ae, #5cd1cb);
    }
  }
}

//Content with images at the CENTER

.containerImageCenter {
  @media (max-width: 1200px) {
    width: 100%;
  }
  &_image {
    max-width: 40rem;
    margin: 2rem 0;
    @media (max-width: 600px) {
      max-width: 18rem;
    }
  }
}

.containerContentCenter {
  text-align: center;
  width: 100%;
  padding: 5rem 0 2.5rem 0;
  font-size: 2rem;
  font-weight: 700;
  > p {
    background: -webkit-linear-gradient(45deg, #2871ff, #28ffd8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 2rem 2rem 0.5rem 2rem;
    flex-direction: column-reverse;
  }
  &_title {
    line-height: 2.5rem;
    position: relative;
    display: inline-block;
    z-index: 2;
    @media (max-width: 600px) {
      width: 100%;
      font-size: 1.5rem;
    }
  }
  &_vector {
    position: absolute;
    left: 39.5rem;
    top: 8.6rem;
    z-index: 1;
    //mobile and tablets
    @media (max-width: 414px) {
      left: 13.5rem;
      top: 8.5rem;
      width: 7.5rem;
    }
    @media (min-width: 415px) and (max-width: 899px) {
      display: none;
    }
    @media (min-width: 768px) and (max-width: 768px) {
      left: 25.8rem;
      top: 6rem;
      width: 9rem;
    }
    @media (min-width: 769px) and (max-width: 1023px) {
      display: none;
    }
    @media (min-width: 1024px) and (max-width: 1024px) {
      left: 34rem;
      top: 6rem;
      width: 9rem;
    }
  }
}

.button {
  background: linear-gradient(90.93deg, $color-young-blue -20.28%, $color-primary 171.61%);
  border-radius: 8px;
  border: none;
  padding: 14px 32px;
  margin-top: 1.7rem;
  font-size: 12px;
  color: var(--color-landingblack5);
  font-weight: 700 !important;
  background-color: $color-young-blue;
  &:hover {
    background: linear-gradient(90.93deg, $color-primary -20.28%, $color-young-blue 171.61%);
    box-shadow: 0 15px 30px rgba(51, 204, 255, 0.5) !important;
  }
}

// SLIDER STYLES

.containerContentSlider {
  text-align: center;
  width: 100%;
  padding: 5rem 0 0.5rem 0;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 2rem 2rem 0.5rem 2rem;
    flex-direction: column-reverse;
  }
}

.slider {
  background-color: $color-primary;
  min-height: 700px;
  max-width: 1440px;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  &_logo {
    max-width: 380px;
    @media (max-width: 600px) {
      width: 90%;
    }
  }
  &_text {
    line-height: 2rem;
    margin-top: 2rem;
    opacity: 0.5;
    margin-bottom: 1.5rem;
    transition: 0.5s;
    width: 100%;
    font-size: 20px;
    font-weight: 600 !important;
    color: var(--color-landingblack5);
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  &_button {
    background: $color-young-blue;
    border-radius: 8px;
    border: none;
    z-index: 2;
    padding: 14px 32px;
    margin-top: 0.5rem;
    left: 36rem;
    font-size: 12px;
    color: var(--color-landingblack5);
    font-weight: 700 !important;
    background-color: $color-young-blue;
    &:hover {
      background: linear-gradient(90.93deg, $color-primary -20.28%, $color-young-blue 171.61%);
      box-shadow: 0 15px 30px rgba(51, 204, 255, 0.5) !important;
    }
  }
  &_sliderLib {
    padding: 2rem 2rem 2rem 2rem;
    margin-top: 1rem;
    max-width: 75rem;
    @media (max-width: 1200px) {
      max-width: 80vw;
    }
    @media (max-width: 400px) {
      max-width: 90vw;
    }
  }
  &_images {
    max-width: 50px;
    max-height: 50px;
  }
}

.sliderCard {
  padding: 2rem 3rem;
  margin: 2.5rem 1rem;
  border-radius: 0.7rem;
  text-align: left;
  min-height: 20.5rem;
  max-width: 25rem;
  border: 1px solid green;
  flex-direction: column;
  @media (max-width: 1200px) {
    min-height: 22rem;
    padding-bottom: 0;
  }
  @media (max-width: 800px) {
    margin: 1.5rem 0.5rem 0;
    min-height: 22rem;
    padding: 0.5rem 1rem;
  }
  &_header {
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 8rem;
    max-height: 8rem;
  }
  &_imagesContainer {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    height: 100px;
  }
  &_images {
    max-width: 150px;
    max-height: 40px;
  }
  &_h5 {
    margin-top: 0.8rem;
    > h5 {
      color: $color-secondary;
      font-size: 24px;
      text-align: left;
    }
  }
  &_chip {
    font-size: 12px;
    background-color: var(--color-landingblack10);
    padding: 0.3rem;
    border-radius: 0.4rem;
  }
  &_text {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.8;
    min-height: 6rem;
    color: var(--color-landingblack25);
  }
  &_bottom {
    max-width: 85%;
    margin-top: 1rem;
    line-height: 1.8;
    display: flex;
    flex-direction: row;
    > p {
      font-size: 14px;
      font-weight: 900;
      color: var(--color-landingblack10);
    }
    > button {
      svg {
        margin-left: 20px;
        width: 20px;
        height: 20px;
        padding: 4px;
        border-radius: 99999px;
        background-color: var(--color-landingblack5);
        path {
          fill: var(--color-landingblack10) 0;
        }
      }
    }
    @media (max-width: 1400px) {
      font-size: 11px;
    }
  }
}
