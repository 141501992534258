@import "../../../../shared/styles/variables.scss";
@import "../../../../shared/styles/mixins.scss";

.wrapper {
  @include createCard;
  max-width: 450px;
  padding: pxToRem(30);
  background-color: var(--color-landingblack88);
  margin: auto;
  box-shadow: -30px 50px 75px rgba(0, 0, 0, 0.8);
  border-radius: 15px;

  &.bordered {
    border: 2px solid #28f9da;
  }
  @media (max-width: 900px) {
    padding: 25px 20px;
  }
  // @media (max-height: 690px) {
  //   overflow-x: auto;
  //   max-height: calc(100vh - 150px);
  //   @include customScrollbar;
  // }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    position: relative;

    .closeIcon {
      position: absolute;
      right: 0;
    }

    * {
      stroke: var(---color-secondary) !important;
      fill: #28f9da !important;
    }
  }

  .headerTitle {
    color: #fff;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
  }

  .authOption {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid var(--color-black50);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: pxToRem(17);
    color: var(--color-black25);
    font-weight: bold;
    gap: pxToRem(15);

    .title {
      font-weight: 700;
      font-size: pxToRem(18);
    }
  }

  .textBtn {
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #28f9da;
    margin: 1rem 0px;
  }

  .footer {
    color: #828282;
    text-align: center;
    font-weight: 400;
    font-size: 10px;

    .textLink {
      color: #28f9da;
    }
  }
}
