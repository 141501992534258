@import './variables.scss';

@mixin gradientText {
  background: $gradient-primary;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin centerFlexContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin centerBlockContent {
  display: inline-block;
  justify-content: center;
  align-items: center;
}

@mixin authThirdPartyBtn {
  width: 2.625rem;
  height: 2.625rem;
  border: 1px solid $color-black10;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 7.875px;
  @include centerFlexContent;
  @include respond(tab-land) {
    border-radius: 12px;
    width: 4rem;
    height: 4rem;
  }
}

@mixin inputBoxStyle {
  background-color: $color-black80;
  height: 3rem;
  width: 100%;
  border: 1px solid $color-black75;
  padding: 0 15px;
  border-radius: $border-radius3;
  color: $color-black25;
  font: inherit;
  font-weight: 600;
  font-size: $font-size-s;

  &::placeholder {
    font-weight: 600;
    font-size: 13px;
    color: var(--color-aux40);
  }
}

@mixin spaceBetweenFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@mixin createHeading {
  font-family: $font-family;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  color: var(--color-aux152);
}

@mixin createInputBox {
  padding: 14px 15px 13px;
  font-family: $font-family;
  border: 1px solid var(--color-aux37);
  color: var(--color-aux154);
  border-radius: 8px;
  outline-width: 0;
  height: 3rem;

  background-color: transparent;
  transition: all 0.3s linear;

  &:focus {
    border: 1px solid var(--color-black50);
  }
}

@mixin createInputBoxNoBorder($bgColor) {
  padding: 12px 10px;
  font-family: $font-family;
  border: 1px solid $bgColor;
  border-radius: 8px;
  outline-width: 0;
  height: 2.5rem;
  background-color: transparent;

  @include respond(tab-land) {
    height: 2.5625rem;
  }
}

@mixin createCard {
  background-color: $color-black80;
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  height: max-content;
}

@mixin customScrollbar {
  &::-webkit-scrollbar {
    width: 0;
    height: 5px;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: var(--color-aux15);
    }
  }
}

@mixin gridAutoLayout($minSize) {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax($minSize, 1fr));
  gap: 1.2rem;
}

@mixin customScrollbarX {
  &::-webkit-scrollbar {
    height: 0;
    width: 5px;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      border-radius: 20px;
    }

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: var(--color-aux15);
    }
  }
}

@mixin svgHoverColorChange {
  svg {
    path {
      transition: 0.2s;
      fill: $color-secondary !important;
    }
  }
}

@mixin overflowEllipses($width: 100%) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $width;
}

// apply this to every gradient-bordered element!
@mixin makeGradBorder($bg-color, $width, $radius: 0) {
  background: linear-gradient($bg-color, $bg-color) padding-box,
    $gradient-primary border-box;
  border: $width solid transparent;
  border-radius: $radius;
  color: $color-secondary;
}

@mixin makeGradBorderAnimated($bg-color, $width, $radius: 0) {
  background: linear-gradient($bg-color, $bg-color) padding-box,
    $gradient-primary-animated border-box;
  background-size: 300% 100%;
  @include animation('gradient 5s linear infinite');
  &:hover {
    @include animation('gradient 1s linear infinite');
  }
  border: $width solid transparent;
  border-radius: $radius;
  // color: $color-secondary;
}

@mixin gradientBackground {
  background: $gradient-primary-animated;
  background-size: 300% 100%;
  @include animation('gradient 5s linear infinite');
  &:hover {
    @include animation('gradient 1s linear infinite');
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin keyframes($animation_name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@include keyframes(gradient) {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}

// media query manager
/*
0 - 600px:        phone
600px - 900px:    tablet portrait
900px - 1200px:   tablet landscape
[1200 - 1800]px is where our normal styles apply
1800px + :        big desktop


$breakpoint argument choices:
-phone
-tab-port
-tab-land
-big-desktop

order: base + typography > general layout + grid > page layout > components
*/

// Mobile First Approach
@mixin respond($breakpoint) {
  @if $breakpoint == tab-port {
    @media (min-width: 37.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media (min-width: 56.25em) {
      @content;
    }
  }
  @if $breakpoint == tab-big {
    @media (min-width: 62.5em) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 75em) {
      @content;
    }
  }
  // @if $breakpoint == big-desktop {
  //     @media (min-width: 112.5em) {@content;}
  // }
}

@mixin responsive_mb {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin responsive-grid ($minSize) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($minSize, 1fr));
}