@import '../../../shared/styles/variables.scss';
@import '../../../shared/styles/mixins.scss';

.modal {
  // caution: Never use transform property here!
  border-radius: $border-radius4;
  overflow: hidden;
  position: fixed;
  opacity: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  z-index: 1001;
  transition: 0.3s all;
  @include centerFlexContent;
  @media (max-width: 600px) {
    width: 90%;
  }
  &.isFullHeight {
    // Please if you change this check all components are using it
    width: 100%;
    overflow-y: auto;
    height: 80vh;
    max-height: calc(100vh - 50px);
    @include centerFlexContent;
    @include customScrollbar;
  }

  &.open {
    top: 10%;
    opacity: 1;
  }

  &.hostOpen {
    top: 10%;
    opacity: 1;
    overflow: visible;
  }

  &.open > &.isBg {
    background-color: transparent;
  }

  &.isBg {
    background-color: $color-black80;
  }
}
