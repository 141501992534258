@import '../../../../../shared/styles/variables.scss';
@import '../../../../../shared/styles/mixins.scss';
.wrapper {
  padding: 20px 20px 30px;
  position: relative;
  background: var(--color-black80);
  box-shadow: -30px 50px 75px rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: pxToRem(394);
  height: pxToRem(494);

  .closeIcon {
    display: flex;
    margin-left: auto;
    svg {
      transform: scale(1.2);
    }

    * {
      stroke: var(---color-secondary) !important;
      fill: #28f9da !important;
    }
  }

  .email_input {
    @include createInputBox;
    border-radius: 8px;
    padding: 14px 15px 13px;
    color: var(--color-black5);
    background: var(--color-black88);
    width: 100%;
    height: pxToRem(47);
    font-size: 13px;
    font-weight: 700;

    &::placeholder {
      color: var(--color-black50) !important;
      font-weight: 600;
      font-size: 13px;
    }
  }

  .images {
    margin-bottom: pxToRem(20) !important;
  }
  .text {
    font-weight: 700;
    font-size: pxToRem(18);
    color: var(--color-black5);
    padding-bottom: pxToRem(20);
  }
}

.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .cancelBtn {
    color: var(--color-black5);
    font-weight: 700;
    font-size: 13px;
    padding: 10px 20px;
    margin-right: 1rem;
  }
}

.divider {
  border: 1px solid var(--color-black50);
  margin-bottom: pxToRem(20);
  width: 100%;
  margin-top: pxToRem(40);
  margin-bottom: pxToRem(20);
}
