@import '../../../../shared/styles/functions.scss';
@import '../../../../shared/styles/mixins.scss';
@import '../../../../shared/styles/variables.scss';

@mixin style($theme) {
  .button {
    background-color: $blue;
    text-align: center;
    justify-content: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
    border: none;
    font-size: pxToRem(13);
    padding: 13px 15px;
    border-radius: 5px;
    color: $white;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.2s;

    &--secondary-outline {
      background-color: transparent;
      border: 1px solid dark-light($neon, $blue, $theme);
      color: dark-light($black-5, $gray-05, $theme);
      padding: 15px 30px;
    }

    &.small {
      padding: 12px 30px;
      font-size: pxToRem(12);
    }

    &:hover,
    &:active {
      opacity: 0.8;
    }
  }
}

:global(.light) {
  @include style('light');
}

:global(.dark) {
  @include style('dark');
}
