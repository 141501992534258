@import '../../../../shared/styles/variables.scss';
@import '../../../../shared/styles/mixins.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.Container {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
  font-family: $font-family;
  div {
    line-height: 1.6;
  }
}

.ContentWrapper {
  margin: auto;
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  .left {
    width: 60%;
  }
  .right {
    width: 40%;
  }
  @media (max-width: 1150px) {
    flex-direction: column;
    .left,
    .right {
      width: 100%;
    }
  }

  .right {
    margin-top: 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-aux68);
    @media (min-width: 900px) {
      padding: 50px 80px;
    }
    @media (max-width: 899px) and (min-width: 600px) {
      padding: 50px 40px;
    }
    @media (max-width: 999px) {
      padding: 30px 15px;
      margin-top: 0px;
    }

    textarea,
    select,
    input[type='date'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='email'],
    input[type='month'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    input[type='time'],
    input[type='url'],
    input[type='week'] {
      text-align: left;
      -webkit-appearance: none;
      background-color: transparent;
      border-color: $color-black75;
      color: $color-black25;
      font-weight: 500;
      &::placeholder {
        color: $color-black75;
      }
    }
  }

  @include respond(tab-land) {
    .left {
      display: block;
    }
  }
}
